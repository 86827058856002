.messages {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 15px;

  display: flex;
  flex-direction: column-reverse;

  .message-row {
    display: flex;
    width: 100%;

    .message {
      border-radius: 7.5px;
      margin: 15px 0;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      max-width: 60%;

      .info {
        width: 100%;

        .from {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.06em;
          color: #525252;
        }

        .text {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;

          white-space: pre-wrap;      /* CSS3 */
          white-space: -moz-pre-wrap; /* Firefox */
          white-space: -pre-wrap;     /* Opera <7 */
          white-space: -o-pre-wrap;   /* Opera 7 */
          word-wrap: break-word;      /* IE */
        }
      }

      .delivery {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;

        display: flex;
        align-items: flex-end;
        letter-spacing: 0.1em;

        .status {
          color: @primary-color;
        }
      }
    }

    .message-from-other {
      background: rgb(235, 235, 235);

      .delivery {
        flex-direction: row;
        padding-left: 5px;

        .time {
          padding-right: 5px;
        }
      }
    }

    .message-from-me {
      background: fade(@primary-color, 10%);
      flex-direction: row-reverse;
      text-align: start;

      .delivery {
        flex-direction: row-reverse;
        padding-right: 5px;

        .time {
          padding-left: 5px;
        }
      }
    }
  }

  .message-row-from-other {
    justify-content: flex-start;
  }

  .message-row-from-me {
    justify-content: flex-end;
  }
}

@primary-color: #001e2e;@primary-1: rgba(0, 30, 46, 0.25);@link-color: #5c0011;@success-color: #001e2e;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);