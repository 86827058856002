.active-room-header {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;

  .info {
    display: flex;
    justify-content: flex-start;

    .image {
      padding-right: 15px;
    }

    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .writing {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #000000;
      opacity: 0.7;
    }
  }

  .count {
    color: @primary-color;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }
}

@primary-color: #001e2e;@primary-1: rgba(0, 30, 46, 0.25);@link-color: #5c0011;@success-color: #001e2e;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);